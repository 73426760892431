import { StageInfo } from '@shared/types/StageInfo';

export const localStage = 'localhost';
export let PATH_TO_WEBAPP_NGINX = '';
export let PATH_TO_CORE_API = '';
export let PATH_TO_RAW_STATISTICS_DATA_STORAGE_API = '';
export let PATH_TO_AUTH_API = '';
export let PATH_TO_SFTP_SERVICE = '';
export let PATH_TO_DEVICE_CONFIGURATION_STORAGE = '';
export let PATH_TO_DEACTIVATED_DEVICE_TRACKER = '';
export let PATH_TO_SD_UPLOADER_API = '';
export let PATH_TO_PATIENT_APP = '';
export let PATH_TO_STATISTICS_GATEWAY = '';
export let PATH_TO_DEVICE_EVENT_STORAGE = '';
export const getBaseAddress = (): string => {
  // prod_diagnostics runs under today stage.
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod_diagnostics') {
    return 'prismacloud.green';
  } else {
    return localStage;
  }
};

export const GetBucharestUrl = (): string => {
  if (STAGE_NAME === localStage) {
    return '';
  }
  return '';
};

export const query = {
  NOT_PERFORMED: 'NOT_PERFORMED',
  PERFORMED: 'PERFORMED',
} as const;

export const STAGE_NAME = getBaseAddress();
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod_diagnostics') {
  const protocol = window.location.protocol;
  const baseAddress = getBaseAddress();

  PATH_TO_WEBAPP_NGINX = window.location.origin;
  PATH_TO_CORE_API = `${protocol}//core.${baseAddress}`;
  PATH_TO_AUTH_API = `${protocol}//auth2.${baseAddress}`;
  PATH_TO_SFTP_SERVICE = `${protocol}//sftp.${baseAddress}`;
  PATH_TO_SD_UPLOADER_API = `${protocol}//sdupload.${baseAddress}`;
  PATH_TO_DEVICE_CONFIGURATION_STORAGE = `${protocol}//dcs.${baseAddress}`;
  PATH_TO_DEACTIVATED_DEVICE_TRACKER = `${protocol}//ddt.${baseAddress}`;
  PATH_TO_RAW_STATISTICS_DATA_STORAGE_API = `${protocol}//rsds.${baseAddress}`;
  PATH_TO_PATIENT_APP = `${protocol}//my.${baseAddress}`;
  PATH_TO_STATISTICS_GATEWAY = `${protocol}//statisticsgateway.${baseAddress}`;
  PATH_TO_DEVICE_EVENT_STORAGE = `${protocol}//eventstorage.${baseAddress}`;
} else if (process.env.NODE_ENV === 'dev_local') {
  // Uncomment to investigate performance problems.
  // const React = require('react');
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React, {trackAllPureComponents: true});
  PATH_TO_WEBAPP_NGINX = `http://${localStage}:3000`;
  PATH_TO_CORE_API = 'http://localhost:5100';
  PATH_TO_RAW_STATISTICS_DATA_STORAGE_API = 'http://localhost:59218';
  PATH_TO_AUTH_API = 'http://localhost:5202';
  PATH_TO_SFTP_SERVICE = 'http://localhost:5610';
  PATH_TO_SD_UPLOADER_API = 'http://localhost:14575';
  PATH_TO_DEVICE_CONFIGURATION_STORAGE = 'http://localhost:21233';
  PATH_TO_DEACTIVATED_DEVICE_TRACKER = 'http://localhost:5134';
  PATH_TO_PATIENT_APP = 'http://localhost:8381';
  PATH_TO_STATISTICS_GATEWAY = 'http://localhost:5147';
  PATH_TO_DEVICE_EVENT_STORAGE = 'http://localhost:5027';
} else {
  PATH_TO_WEBAPP_NGINX = window.location.origin;
  PATH_TO_CORE_API = 'https://core.prismacloud.today';
  PATH_TO_AUTH_API = 'https://auth2.prismacloud.today';
  PATH_TO_SFTP_SERVICE = 'https://sftp.prismacloud.today';
  PATH_TO_SD_UPLOADER_API = 'https://sdupload.prismacloud.today';
  PATH_TO_DEVICE_CONFIGURATION_STORAGE = 'https://dcs.prismacloud.today';
  PATH_TO_PATIENT_APP = 'https://my.prismacloud.today';
  PATH_TO_STATISTICS_GATEWAY = 'https://statisticsgateway.prismacloud.today';
  PATH_TO_DEVICE_EVENT_STORAGE = 'https://eventstorage.prismacloud.today';
}

declare global {
  interface Window {
    PATH_TO_CORE_API: string;
    PATH_TO_DEVICE_CONFIGURATION_STORAGE: string;
    PATH_TO_AUTH_API: string;
    PATH_TO_PATIENT_APP: string;
    msCrypto: any;
  }
}

// Make constants accessible by UI tests.
window.PATH_TO_CORE_API = PATH_TO_CORE_API;
window.PATH_TO_DEVICE_CONFIGURATION_STORAGE = PATH_TO_DEVICE_CONFIGURATION_STORAGE;
window.PATH_TO_AUTH_API = PATH_TO_AUTH_API;
window.PATH_TO_PATIENT_APP = PATH_TO_PATIENT_APP;

export const USER_IDLE_LIMIT_SECONDS = 3600;
export const CHRONOMETER_TICK_INTERVAL = 30;

export const STATUS_MESSAGE_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};

export enum LogoutReason {
  AutotestLogout = 'AutotestLogout',
  LogoutByRequest = 'LogoutByRequest',
  Idle = 'Idle',
  PasswordChange = 'PasswordChange',
  Unknown = 'Unknown',
}

export const AuthErrorResponses = {
  secondFactorRequired: 'second_factor_required',
  secondFactorFailed: 'login_twoFactorAuthenticationFailed',
  loginIsLocked: 'login_locked',
  invalidUserOrPassword: 'login_invalidUserOrPassword',
  invalidToken: 'InvalidToken',
  passwordCannotBeSet: 'PasswordCannotBeSet',
};

export enum CLAIMS {
  MED_SETTINGS_PERMISSIONS = 'med_settings_permissions',
  USER_OTHER_REGULAR_PERMISSIONS = 'user_other_regular_permissions',
  USER_OTHER_ORGMANAGER_PERMISSIONS = 'user_other_orgmanager_permissions',
  ORG_SETTINGS_PERMISSIONS = 'org_settings_permissions',
  PATIENT_PERMISSIONS = 'patient_permissions',
  ORGANIZATION_PERMISSIONS = 'organization_permissions',
  ORGANIZATION_OTHER_PERMISSIONS = 'organization_other_permissions',
  DEVICES_PERMISSIONS = 'devices_permissions',
  DEACTIVATED_DEVICES = 'dectivated_devices',
  MOVE_DEVICES_BETWEEN_ORGANISATIONS = 'move_devices_between_organisations',
  PORTAL_USERS = 'portal_users',
  DEVICE_SETTINGS = 'device_settings',
  SD_CARD_UPLOAD_PERMISSIONS = 'sd_card_upload_permissions',
  DOWNLOAD_FIRMWARE_PERMISSIONS = 'download_firmware_permissions',
  ALL_PICS_PERMISSIONS = 'pic_permissions',
  OWN_PIC_PERMISSIONS = 'pic_self_permissions',
  ORGANISATION_PAIRING_PERMISSIONS = 'organisation_pairing_permissions',
  PREREGISTER_DEVICE = 'preregister_device',
  SHARE_PATIENT = 'share_patient',
  EDIT_PRISMA_SMART_CONFIGURATION = 'edit_prismaSmart_configuration',
  EDIT_PRISMA_LINE_SLEEP_CONFIGURATION = 'edit_prismaLine_sleep_configuration',
  EDIT_PRISMA_LINE_VENTI_CONFIGURATION = 'edit_prismaLine_venti_configuration',
  EDIT_PRISMA_VENT_CONFIGURATION = 'edit_prismaVent_configuration',
  PRISMA_SMART_TELEMONITORING_SWITCHING = 'prismaSmart_telemonitoring_switching',
}

export type PermissionClaims = Record<CLAIMS, PERMISSIONS>;

export enum PERMISSIONS {
  NONE = 0,
  READ = 1,
  EDIT = 2,
  CREATE = 4,
  DELETE = 8,
  All = PERMISSIONS.READ | PERMISSIONS.EDIT | PERMISSIONS.CREATE | PERMISSIONS.DELETE,
}

export const USERS_NUMBER_ON_PAGE = 15;
export const DEVICES_NUMBER_ON_PAGE = 15;
export const PATIENTS_NUMBER_ON_PAGE = 15;
export const SCHEDULED_PATIENTS_NUMBER_ON_PAGE = 5;
export const ORGANISATIONS_NUMBER_ON_PAGE = 15;

function isOnStage(...stages: string[]) {
  return stages.indexOf(STAGE_NAME) > -1;
}

export const SENTRY_CONFIG = {
  DSN: 'https://423ed5d5f788491a9833a08be82cd21f@sentry-dev.prismacloud.cc/3',
  IsEnabled: STAGE_NAME !== localStage,
};

export const MATOMO_CONFIG = {
  Url: 'https://matomo.prismacloud.cc',
  SiteId: parseInt('3'),
  IsEnabled: STAGE_NAME !== localStage,
};

export const MUI_LICENSE_KEY =
  '19a3116cf1de30c2dbb7a5e4b3446535Tz03MDQ2NixFPTE3MjA3MjA2NDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';

export const getStageInfo = (): StageInfo => {
  // prod_diagnostics runs under today stage.
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod_diagnostics') {
    return {
      version: '9.9.9-211',
      udiCompanyIdentifier: '(01)04050384287272(10)',
      copyright: '© 2016-2024 Löwenstein Medical Technology GmbH + Co. KG',
    };
  } else {
    return {
      version: '1.2.3',
      udiCompanyIdentifier: '(01)04050384287272(10)',
      copyright: '© 2016–2023 Löwenstein Medical Technology GmbH + Co. KG',
    };
  }
};
