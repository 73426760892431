import { useDispatch } from 'react-redux';

import { ActionWithPayload } from './typings';
import {
  ShowNotificationPayload,
  HideNotificationPayload,
  ShowNotificationWithTimeoutPayload,
  ShowRevokeNotificationPayload,
} from './payloadTypes/notificationPayloads';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION_WITH_TIMEOUT = 'SHOW_NOTIFICATION_WITH_TIMEOUT';
export const SHOW_REVOKE_NOTIFICATION = 'SHOW_REVOKE_NOTIFICATION';
export const SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT = 'SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT';

export const ERROR_ID = 'ERROR_ID';
export const SUCCESS_ID = 'SUCCESS_ID';
export const INFO_ID = 'INFO_ID';
export const GENERIC_ERROR_KEY = 'Generic_Error';

export const showNotification = (payload: ShowNotificationPayload): ActionWithPayload<ShowNotificationPayload> => {
  return {
    type: SHOW_NOTIFICATION,
    payload,
  };
};

export const hideNotification = (payload: HideNotificationPayload): ActionWithPayload<HideNotificationPayload> => {
  return {
    type: HIDE_NOTIFICATION,
    payload,
  };
};

export const showNotificationWithTimeout = (
  payload: ShowNotificationWithTimeoutPayload,
): ActionWithPayload<ShowNotificationWithTimeoutPayload> => {
  return {
    type: SHOW_NOTIFICATION_WITH_TIMEOUT,
    payload,
  };
};

export const showRevokeNotification = (
  payload: ShowRevokeNotificationPayload,
): ActionWithPayload<ShowRevokeNotificationPayload> => {
  return {
    type: SHOW_REVOKE_NOTIFICATION,
    payload,
  };
};

export const showRevokeNotificationWithTimeout = (
  payload: ShowRevokeNotificationPayload,
): ActionWithPayload<ShowRevokeNotificationPayload> => {
  return {
    type: SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT,
    payload,
  };
};

export const useShowNotificationWithTimeout = () => {
  const dispatch = useDispatch();

  return (payload: ShowNotificationWithTimeoutPayload) => {
    dispatch(showNotificationWithTimeout(payload));
  };
};
