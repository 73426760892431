import clsx from 'clsx';
import React, { createContext, useContext, useEffect, useRef } from 'react';

import { ShowBusyIndicatorInfo, updateShowBusyIndicator } from '@controls/BusyIndicator/busyIndicatorUtils';

import './BusyIndicator.css';

const BusyIndicationIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='10mm' height='10mm' viewBox='0 0 56.12 56.12' version='1.1'>
    <g>
      <path
        d='M 56.122781,-1.8536333e-6 A 56.1228862,56.1228864 0 0 0 0,56.1227836 H 9.1305 A 46.998933,46.998936 0 0 1 56.122781,9.1304971 46.998933,46.998936 0 0 1 103.12803,56.1227836 46.998933,46.998936 0 0 1 56.122781,103.12803 v 9.1305 A 56.1228862,56.1228864 0 0 0 112.25853,56.1227836 56.1228862,56.1228864 0 0 0 56.122781,-1.8536333e-6 Z'
        style={{ fill: '#a9ca00', stroke: 'none' }}
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0 56.12 56.12'
          to='180 56.12 56.12'
          dur='2s'
          repeatCount='indefinite'
          calcMode='spline'
          keySplines='.7 .1 .1 .9;.7 .1 .1 .9;.7 .1 .1 .9'
          values='0 56.12 56.12;0 56.12 56.12;90 56.12 56.12;180 56.12 56.12'
        />
      </path>
      <path
        d='M 56.102674,17.665982 A 38.453552,38.453552 0 0 0 17.665973,56.137325 l 8.980686,-0.0041 A 29.47287,29.47287 0 0 1 56.106721,26.646654 29.47287,29.47287 0 0 1 85.593343,56.106716 29.47287,29.47287 0 0 1 56.133275,85.593342 l 0.0041,8.980678 A 38.453552,38.453552 0 0 0 94.574024,56.102679 38.453552,38.453552 0 0 0 56.102674,17.665982 Z'
        style={{ fill: '#929292', stroke: 'none' }}
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0 56.12 56.12'
          to='180 56.12 56.12'
          begin='0.15s'
          dur='2s'
          repeatCount='indefinite'
          calcMode='spline'
          keySplines='.7 .1 .1 .9;.7 .1 .1 .9;.7 .1 .1 .9'
          values='0 56.12 56.12;0 56.12 56.12;90 56.12 56.12;180 56.12 56.12'
        />
      </path>
      <path
        d='m 56.090361,35.066075 a 21.05348,21.05348 0 0 0 -21.0243,21.08357 l 9.261322,-0.01304 A 11.792151,11.792151 0 0 1 56.103398,44.327396 11.792151,11.792151 0 0 1 67.912619,56.103413 11.792151,11.792151 0 0 1 56.136597,67.912627 l 0.01304,9.261317 A 21.05348,21.05348 0 0 0 77.173937,56.090376 21.05348,21.05348 0 0 0 56.090364,35.066075 Z'
        style={{ fill: '#565656', stroke: 'none' }}
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          from='0 56.12 56.12'
          to='180 56.12 56.12'
          begin='0.3s'
          dur='2s'
          repeatCount='indefinite'
          calcMode='spline'
          keySplines='.7 .1 .1 .9;.7 .1 .1 .9;.7 .1 .1 .9'
          values='0 56.12 56.12;0 56.12 56.12;90 56.12 56.12;180 56.12 56.12'
        />
      </path>
    </g>
  </svg>
);

export const SmallBusyIndicator: React.FC<{
  className?: string;
}> = ({ className }) => (
  <div className={clsx('busy-indicator', className)}>
    <BusyIndicationIcon />
  </div>
);

/**
 * Render busy indicator in BusyContainer.
 * Now it do not render busy indicator itself but notifies BusyContainer to render Busy indication.
 */
export const BusyIndicator: React.FC<{
  lockUI?: boolean;
  className?: string;
}> = ({ lockUI, className }) => {
  const busyIndicatorContext = useContext(BusyIndicatorContext);
  useEffect(() => {
    const opt = {
      lockUI,
      className,
    };
    busyIndicatorContext.show(opt);
    return () => {
      busyIndicatorContext.hide(opt);
    };
  }, []);

  return null;
};

export const BusyContainer: React.FC<{
  // Is required to ensure a meaningful name is provided hence simplifying debugging
  className: string;
  lockUI?: boolean;
  position: 'top' | 'center';
  children?: React.ReactNode;
}> = (props) => {
  // State behaves unpredictable when is changed by concurrent callbacks,
  // therefore, all work with the state is moved out of callbacks, and the state is stored as Ref.
  const showBusyIndicator = useRef<ShowBusyIndicatorInfo[] | false>(false);
  const actions = useRef<{ action: 'show' | 'hide'; info: ShowBusyIndicatorInfo }[]>([]);

  // Every render is important as it is used for setting the state, so we call re-render manually when it's needed.
  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleShow = (info: ShowBusyIndicatorInfo) => {
    actions.current = [...actions.current, { action: 'show', info }];
    forceUpdate();
  };

  const handleHide = (info: ShowBusyIndicatorInfo) => {
    actions.current = [...actions.current, { action: 'hide', info }];
    forceUpdate();
  };

  const newState = updateShowBusyIndicator(showBusyIndicator.current, actions.current);
  actions.current = [];

  if (newState != showBusyIndicator.current) {
    showBusyIndicator.current = newState;
    forceUpdate();
  }

  return (
    <BusyIndicatorContext.Provider value={{ show: handleShow, hide: handleHide }}>
      <div className={clsx('busy-container', showBusyIndicator.current && 'busy-container__show', props.className)}>
        {props.children}
        {showBusyIndicator.current && (
          <>
            <div
              className={clsx(
                'busy-indicator',
                'busy-container__overlaid',
                {
                  'busy-indicator__show-on-top': props.position === 'top',
                },
                showBusyIndicator.current.map((x) => x.className),
              )}
            >
              <BusyIndicationIcon />
            </div>
            {(props.lockUI || showBusyIndicator.current.some((x) => x.lockUI)) && (
              <div className={clsx('busy-container__lock-ui')} />
            )}
          </>
        )}
      </div>
    </BusyIndicatorContext.Provider>
  );
};

const BusyIndicatorContext = createContext({
  show: (_opt: { lockUI?: boolean; className?: string }) => {},
  hide: (_opt: { lockUI?: boolean; className?: string }) => {},
});
