import dayjs, { Dayjs } from 'dayjs';
import i18next from 'i18next';

import { eachDayOfInterval } from './dateUtils';
import { getStoredOrDefaultCulture } from './user';

/* explicitly use YYYY-MM-DD format (ISO without time part), otherwise the date will be shifted to UTC time
    (which will e.g. lead to day shifting - selecting 15.12.2017 results in 14.12.2017 (at 11PM).
    Using the format prevents it. */
export const dateFormat = 'YYYY-MM-DD';

export const getUserLocalizedMoment = (date?: string | Date | Dayjs, format?: string) => {
  if (dayjs.isDayjs(date)) {
    return date.locale(getStoredOrDefaultCulture().dayJSLocale);
  }

  return dayjs(date, format, true).locale(getStoredOrDefaultCulture().dayJSLocale);
};

export const getUserLocalizedDate = (date?: string | Date | Dayjs, format?: string) => {
  return getUserLocalizedMoment(date, format).format('L');
};

export const getSecondsSinceLastSeenAsString = (userLastSeen?: number | string | null) => {
  if (userLastSeen === null || userLastSeen === undefined) {
    return i18next.t('UserList_NeverSeen');
  }

  const userLastSeenNumber =
    typeof userLastSeen === 'number' ? userLastSeen : parseInt(userLastSeen as unknown as string);

  const lastLogInTime = dayjs().add(userLastSeenNumber * -1, 'seconds');
  const isCurrentDate = lastLogInTime.isSame(new Date(), 'day');
  return isCurrentDate ? i18next.t('UserList_TodaySeen') : lastLogInTime.fromNow();
};

/**
 * Gets dates withing given date range (inclusive).
 * @param start Start date of interval.
 * @param end End date of interval.
 */
export const getDatesWithinDateRange = (start: Dayjs, end: Dayjs) => {
  // new Date(year, month, date) conversion is needed because we want to get dates
  // in scope of date range ignoring timezone conversion.
  // If create Date object from a string then it will be automatically converted in browser's timezone.
  // Example:
  //   new Date('2021-01-01') -> Thu Dec 31 2020 14:00:00 GMT-1000 (Hawaii-Aleutian Standard Time)
  //   new Date(2021, 0, 1)   -> Fri Jan 01 2021 00:00:00 GMT-1000 (Hawaii-Aleutian Standard Time)
  return Array.from(
    eachDayOfInterval(
      new Date(start.year(), start.month(), start.date()),
      new Date(end.year(), end.month(), end.date()),
    ),
  );
};

export const getLocalizedNumeric = (number: Number) => {
  const culture = getStoredOrDefaultCulture().dayJSLocale;
  return number.toLocaleString(culture, { useGrouping: false });
};

export const getLocalizedDayMonthAndYear = (dateTimeIso: string) => {
  const culture = getStoredOrDefaultCulture().dayJSLocale;
  return Intl.DateTimeFormat(culture, { day: 'numeric', month: 'short', year: 'numeric' }).format(
    new Date(dateTimeIso),
  );
};

const getLocalizedDayAndMonthImpl = (dateTimeIso: string, isYearShownIfNotCurrent: boolean) => {
  const dateObj = new Date(dateTimeIso);

  const culture = getStoredOrDefaultCulture().dayJSLocale;

  const options: Intl.DateTimeFormatOptions =
    dateObj.getFullYear() == dayjs().year() || !isYearShownIfNotCurrent
      ? { day: 'numeric', month: 'short' }
      : { day: 'numeric', month: 'short', year: 'numeric' };

  if (['ja', 'ko', 'zh-cn'].some((x) => x == culture)) {
    // Add zero-width-space and word-joiner for correct word wrapping.
    return dateObj.toLocaleDateString(culture, options).replace(/\d+/g, '​$&').replace(/\D+/g, '⁠$&');
  }
  if (['bg', 'cs', 'sk'].some((x) => x == culture)) {
    // JS Date uses wrong month short format for these languages — numbers separated by dots instead of shortened words
    // (see https://stackoverflow.com/questions/64506088/cs-cz-locale-and-javascript-new-date-constructor-doesnt-allow-for-shorthand-dat).
    // Dayjs has correct short month format, but doesn't have an option to show 'll'-formatted date without year.
    // Removing the current year manually from dayjs date.
    return dayjs(dateTimeIso).format('ll').replace(dayjs().year().toString(), '').trim();
  }

  return dateObj.toLocaleDateString(culture, options);
};

/*
 * Returns date in format of day and short month according to a current culture.
 * Analog of 'D MMM'.
 */
export const getLocalizedDayAndMonth = (dateTimeIso: string) => {
  const isYearShownIfNotCurrent = false;
  return getLocalizedDayAndMonthImpl(dateTimeIso, isYearShownIfNotCurrent);
};

/*
 * Returns date in format of day and short month according to a current culture.
 * If current year is not equal to a year of given date, adds also a year.
 * Analog of 'D MMM' / 'D MMM YYYY'.
 */
export const getLocalizedDayMonthAndYearIfNotCurrent = (dateTimeIso: string) => {
  const isYearShownIfNotCurrent = true;
  return getLocalizedDayAndMonthImpl(dateTimeIso, isYearShownIfNotCurrent);
};

/*
 * Returns date in format of day, short month and time according to a current culture.
 * Analog of 'D MMM, LT'.
 */
export const getLocalizedDayMonthAndTime = (dateTimeIso: string) => {
  return `${getLocalizedDayAndMonth(dateTimeIso)}, ${getLocalizedTime(dateTimeIso)}`;
};

export const getLocalizedTime = (dateTimeIso: string) => {
  const culture = getStoredOrDefaultCulture().dayJSLocale;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return Intl.DateTimeFormat(culture, { hour: 'numeric', minute: 'numeric', timeZone }).format(new Date(dateTimeIso));
};
