import { OrganisationsRootState } from '@shared/redux/state/organisations';
import { RequestState } from '@shared/constants/request';

export const initOrganisationsRootState: OrganisationsRootState = {
  current: {
    allowTeleConfiguration: true,
    confirmSettingsRequired: false,
    contact: '',
    countryCode: 'DE',
    managerMail: '',
    managerMailConfirmed: false,
    name: '',
    tenant: '',
    twoFactorEnabled: false,
    requestState: RequestState.NOT_STARTED,
    timezoneInfo: {
      timezoneOffset_Minutes: 0,
      displayName: 'UTC',
      id: 'UTC',
    },
    consumingTenants: [],
    providingTenants: [],
    shareAllConsumers: [],
    isExternalLoginUsed: false,
    externalLoginManagerEmail: null,
    externalLoginTenantId: null,
    defaultRoles: null,
    isActivated: false,
  },
  filters: {
    loginType: 'All',
    countries: [],
  },
};
