import { IndexedObject } from '@shared/types/IndexedObject';

export const DeviceCommonFilterLocalizationKeys: IndexedObject<string> = {
  0: 'DeviceList_Filter_LastTransmittedAt_All',
  1: 'DeviceList_Filter_LastTransmittedAt_Modem',
  2: 'DeviceList_Filter_LastReceivedAt_PatientApp',
  3: 'DeviceList_Filter_LastTransmittedAt_Upload',
  4: 'DeviceList_Filter_TelemonitoringDisabled',
  5: 'DeviceList_Filter_RecentlyChangedDeviceConfig',
};

export const ProblematicDeviceFilterLocalizationKeys: IndexedObject<string> = {
  0: 'DeviceList_Filter_ProblematicDevices_MissingData',
  1: 'DeviceList_Filter_ProblematicDevices_WrongDeviceTime',
  2: 'DeviceList_Filter_ProblematicDevices_ProblemsWithDeviceConfig',
};

// Enum is connected to localizations, be aware of it if you change the value.
export enum TeleSettingsFilters {
  Empty = 'Empty',
  RecentlySucceededOutgoingSettings = 'RecentlySucceededOutgoingSettings',
  RecentlyProblematicOutgoingSettings = 'RecentlyProblematicOutgoingSettings',
}

// Enum is connected to localizations, be aware of it if you change the value.
export enum DeviceAssignmentFilters {
  Empty = 'Empty',
  RecentlyAssignedDevice = 'RecentlyAssignedDevice',
  PatientsWithDevice = 'PatientsWithDevice',
  PatientsWithoutDevice = 'PatientsWithoutDevice',
}

export enum PatientNoteFilters {
  Empty = 'Empty',
  CreatedOrUpdatedInLast7Days = 'CreatedOrUpdatedInLast7Days',
  CreatedOrUpdatedInLast14Days = 'CreatedOrUpdatedInLast14Days',
  CreatedOrUpdatedInLast30Days = 'CreatedOrUpdatedInLast30Days',
  NotCreatedOrUpdatedInLast7Days = 'NotCreatedOrUpdatedInLast7Days',
  NotCreatedOrUpdatedInLast14Days = 'NotCreatedOrUpdatedInLast14Days',
  NotCreatedOrUpdatedInLast30Days = 'NotCreatedOrUpdatedInLast30Days',
  NoVisibleOrNotDeletedComments = 'NoVisibleOrNotDeletedComments',
}

export type PrismaAppUsageFilter = 'Empty' | 'PatientUsesPrismaApp' | 'PatientDoesntUsePrismaApp';

// Enum is connected to localizations, be aware of it if you change the value.
export enum SharingStatusFilters {
  /*
   * Means that the value is not selected.
   * */
  Empty = 'Empty',
  /*
   * All patients who belong to current organisation.
   * */
  Own = 'OwnPatients',
  /*
   * Patients, who are not shared to any organization.
   * */
  Private = 'NotSharedIndividuallyToAnyOrganisation',
  /*
   * Patients, who are shared to the organisation.
   * */
  SharedWithMe = 'SharedToCurrentOrganisation',
  /*
   * Patients, who are shared by current organisation.
   * */
  SharedByMe = 'SharedByMeIndividually',
}

export enum DeviceCommonFilter {
  AllTypes = 0,
  LastReceivedAtByModem = 1,
  LastReceivedAtByPatientApp = 2,
  LastReceivedAtByUpload = 3,
  TelemonitoringDisabled = 4,
  RecentlyChangedDeviceConfig = 5,
}

export enum ProblematicDeviceFilter {
  MissingData = 0,
  WrongDeviceTime = 1,
  ProblemsWithDeviceConfig = 2,
}

export enum AdvancedFiltersTypes {
  UserInCharge = 1,
  RecentlySupplied = 2,
  RecentlySucceededOutgoingSettings = 3,
  RecentlyProblematicOutgoingSettings = 4,
}

export const PatientCategoryFilterKey = 'patientCategory';
