// tslint:disable:typedef no-submodule-imports no-default-export
import { call, put } from 'redux-saga/effects';

import * as OrganisationActionTypes from '@shared/redux/actions/organisation-actions';
import { OrganisationApi } from '@shared/api/core-api';
import { OrganisationModel } from '@shared/api/core-api/organisation-api';

export function* fetchGetCurrentOrganisation() {
  try {
    const currentOrganisation = (yield call(OrganisationApi.getCurrent)) as OrganisationModel;
    yield put(OrganisationActionTypes.getCurrentOrganisation.success(currentOrganisation));
  } catch (e) {
    yield put(OrganisationActionTypes.getCurrentOrganisation.failure(e));
  }
}
